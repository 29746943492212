<!--
 * @Description: 床型
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:58:32
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 09:54:19
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增床型</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData" border>
      <!-- <el-table-column label="编号" prop="id" width="100"> </el-table-column> -->
      <el-table-column label="名称" prop="name"> </el-table-column>
      <el-table-column label="可睡人数" prop="number" width="100"> </el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180"> </el-table-column>
      <el-table-column label="操作" width="140">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-bed
    :item="current"
    @close="current = null"
    @success="loadData"
  ></edit-bed>
</template>
<script>
import EditBed from "./edit_bed.vue";

export default {
  components: {
    EditBed,
  },
  data() {
    return {
      activeName: "bed",
      current: null,
      tableData: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.post("admin/v1/bed").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("admin/v1/bed/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>